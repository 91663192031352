<template>
    <modal name="share-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
        <div class="p-10 text-primary-one modal-body">
            <div class="i-border-b-1 border-secondary-four mb-5">
                <p class="fs-20 text-primary-one mb-5">Share with Team</p>
            </div>
            <div class="i-border-b-1 border-secondary-four mb-5 pb-5">
                <div class="flex justify-between">
                    <p class="fs-20 text-primary-one fw-600">Change status</p>
                </div>
                <p>Change the privacy status to public to enable sharing with team or partners</p>
            </div>
            <div class="mb-8">
                <textarea name="" id="" placeholder="Add a message" class="i-border-1 border-secondary-five rounded message-box p-3 text-secondary-two bld-content-area"></textarea>
            </div>
            <div class="i-border-b-1 border-secondary-four mb-5 pb-5">
                <div class="flex justify-between mb-2">
                    <p class="fs-20 text-primary-one fw-600">Share a one time link</p>
                    <div class="flex">
                        <span>Can View</span>
                        <span></span>
                    </div>
                </div>
                <div class="flex justify-between">
                    <input type="text" class="i-border-1 border-secondary-four input-box" placeholder="Generate 4 hour Link ">
                    <p class="h-10 flex items-center shadow-two rounded bg-primary-four p-5 text-primary-three fw-600">Copy link</p>
                </div>
            </div>
            <div class="i-border-b-1 border-secondary-four mb-5 pb-5">
                <div class="flex justify-between mb-2">
                    <p class="fs-20 text-primary-one fw-600">Invite people to collaborate</p>
                    <div class="flex">
                        <span>Can Edit</span>
                        <span></span>
                    </div>
                </div>
                <div class="flex justify-between">
                    <input type="text" class="i-border-1 border-secondary-four input-box" placeholder="Type email here">
                    <p class="h-10 flex items-center shadow-two rounded bg-primary-four p-5 text-primary-three fw-600">Invite</p>
                </div>
            </div>
            <!-- <div class="i-border-b-1 border-secondary-four mb-5 pb-5">
                <div class="flex justify-between input-box">
                    <div class="flex justify-between mb-2">
                        <img src="" alt="">
                        <p class="fs-20 text-primary-one fw-600">Invite people to collaborate</p>
                    </div>
                    <img src="" alt="">
                </div>
                <div class="flex">
                    <span>Can Edit</span>
                    <span></span>
                </div>
            </div> -->
        </div>
    </modal>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "ShareModal",
        props: ['dModal', 'rootItem'],
        data() {
            return {
                //
            }
        },
        watch: {
            dModal: function (val) {
                if (val) {
                    this.openModal();
                } else {
                    this.closeModal();
                }
            },
            rootItem: function(val) {
                if(val) {
                    //
                }
            }
        },
        computed: {
            ...mapGetters({
                loading: "GET_LOADING_STATE",
                activeBrainstormData: "brainstormv1/activeBrainstormData",
            })
        },
        methods: {
            openModal() {
                this.$modal.show('share-modal');
            },
            closeModal() {
                this.$modal.hide('share-modal');
                this.$emit('toggleModalMode', false);
            }
        }
    }
</script>

<style scoped>
.modal-body {
    /* width: 680px; */
}
.message-box {
    width: 390px;
    height: 80px;
}
.input-box {
    width: 390px;
    height: 40px;
}
.vm--modal {
    width: 680px !important;
}
</style>
