<template>
  <div class="relative flex" @click="closeMenuHandler($event)" @keydown="checkCtrl($event)" @keyup="checkCtrl($event)" @mousewheel="checkCtrl($event)" @mousedown="checkCtrl($event)" @mouseup="checkCtrl($event)">
    <div class="i-left-panel shadow-one" v-if="navigationClicked === 1">
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
      <left-part
                 :navigationClicked="navigationClicked"
                 :isolatedModules="isolatedModules"
                 :isolatedModuleId="isolatedModule"
                 @clickNavigation="clickNavigation"
                 @toggleConcept="toggleConcept"
                 @gotoConcept="gotoConcept"
                 @closeConcept="closeConcept"
                 @resetZoom="zoomReset"
                 @toggleIsolation="toggleIsolation"
                 @changedDataFromCollaboration="changedDataFromCollaboration"
                 @toggleToIntel="toggleToIntel"
                 @closeIntel="closeIntel"
                 @intelChartIsolation="intelChartIsolation"
                 @setIntelGroup="setIntelGroup"
                 @removeIsolation="removeIsolation"
                 @removeIntelIsolation="removeIntelIsolation"
                 @moduleIsolation="moduleIsolation" />
    </div>
    <div v-if="!concept && !intel" class="i-right-panel full-height relative" :class="{'bld-full-w-no-left': navigationClicked === 2}">
      <div class="flex flex-col absolute right-0 z-50 ws-heading-box" v-if="navigationClicked === 1">
        <workspace-menu />
      </div>
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo z-50"
           v-if="navigationClicked === 2">
      <div class="" :class="{'': navigationClicked === 2}"
           @mousemove="movedCursorPosition($event)"
           @mouseleave="movedCursorPosition($event, true)">
        <div class="flex justify-between items-center absolute z-50"
            :class="{'tools-panel-cont' : navigationClicked == 1,
                      'tools-panel-cont-full': navigationClicked == 2,
                      'tools-panel-cont-full-width': navigationClicked == 2 && !sketch}">
          <img src="/images/icons/build/navigation.svg" alt="icon" class="cursor-pointer opacity-50 i-icon mr-5 hover:bg-primary-three icon-30 mb-1"
               v-if="navigationClicked === 2" title="Navigation" @click="clickNavigation(1)">
          <div class="absolute tool-cont-pos" :class="{'tool-cont-pos-full': navigationClicked === 2}" v-if="!sketch">
            <div class="tools-panel z-50 h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center">
              <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box" type="text" placeholder="Brainstorm title"
                     v-model="activeBrainstormData.title" @change="updateTitle()">
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 relative">
                <img src="/images/icons/brainstormV2/single.svg" alt="new" @click="newRootChild()" :class="{'opacity-25 pointer-events-none': activeBrainstormData.scenario_id}">
                <img src="/images/icons/brainstormV2/abc.svg" alt="" class="opacity-25 pointer-events-none">
                <img src="/images/icons/brainstormV2/sketch.svg" alt="" @click="toggleSketchMenu">
                <div class="absolute p-5 bg-primary-three shadow-two rounded z-10" id="sketch-menu" v-show="sketchMenu">
                  <h3 class="pb-5 i-border-b-1 border-secondary-two mb-5 fs-14 fw-600 text-secondary-one cursor-pointer" @click="generateSketchCanvas">Create New Sketch</h3>
                  <h3 class="mb-5 fs-14 fw-600 text-secondary-one">Open Existing</h3>
                  <h3 class="mb-5 fs-14 fw-400 text-secondary-one opacity-60 sketch-item cursor-pointer" v-for="(item, index) in activeBrainstormData.sketches" :key="index">{{ item.title }}</h3>
                </div>
              </div>
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-5">
                <img src="/images/icons/brainstormV2/view.svg" alt="" @click="toggleView" :class="viewIndex == 1 ? 'opacity-100' : 'opacity-60'">
                <img src="/images/icons/brainstormV2/sync.svg" alt="" @click="zoomReset">
                <div class="flex">
                  <div class="flex tool-group i-border-r-1 tool-level-box l1c cursor-default i-border-1 border-transparent" />
                  <div class="flex tool-group i-border-r-1 i-ml-5 tool-level-box l2c cursor-default i-border-1 border-transparent"/>
                  <div class="flex tool-group i-border-r-1 i-ml-5 tool-level-box l3c cursor-default i-border-1 border-transparent" />
                  <div class="flex tool-group i-border-r-1 i-ml-5 tool-level-box l4c cursor-default i-border-1 border-transparent" />
                </div>
              </div>
              <div class="flex tool-group ml-5">
                <img src="/images/icons/brainstormV2/wiki.svg" alt="">
                <img class="bs-tool-mr-0" src="/images/icons/brainstormV2/chain.svg" alt="" @click="shareModal = true">
              </div>
            </div>
          </div>
          <div class="absolute tool-cont-pos" :class="{'tool-cont-pos-full': navigationClicked === 2}" v-else-if="sketch">
            <div class="h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center">
              <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box" type="text" placeholder="Sketch title"
                     @change="updateSketchTitle()">
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-1 relative">
                <img src="/images/icons/brainstormV2/selection.svg" alt="" @click="enableCanvas('selectMode')">
                <div @click="strokeList = !strokeList" class="relative">
                  <img src="/images/icons/brainstormV2/pen.svg" alt="">
                  <div v-show="strokeList" class="icon-list i-p-10 absolute bg-primary-three shadow-one flex-col justify-center">
                    <img class="mb-2 p-1" src="/images/icons/brainstormV2/line.svg" alt="" @click="enableCanvas('freeDrawing', {strokeWidth: 1})">
                    <img class="mb-2 p-1" src="/images/icons/brainstormV2/line2.svg" alt="" @click="enableCanvas('freeDrawing', {strokeWidth: 2})">
                    <img class=" p-1" src="/images/icons/brainstormV2/line3.svg" alt="" @click="enableCanvas('freeDrawing', {strokeWidth: 3})">
                    <!-- <img class="mb-2" src="/images/icons/brainstormV2/line4.svg" alt="" @click="enableCanvas('freeDrawing')"> -->
                  </div>
                </div>
                <img src="/images/icons/brainstormV2/arrow.svg" alt="" @click="enableCanvas('arrow')">
                <!-- <img src="/images/icons/brainstormV2/rectangle.svg" alt="" @click="enableCanvas('rect')"> -->
                <div @click="rectList = !rectList" class="relative">
                  <img src="/images/icons/brainstormV2/rectangle.svg" alt="">
                  <div v-show="rectList" class="icon-list i-p-10 absolute bg-primary-three shadow-one flex-col justify-center">
                    <img class="mb-2 p-1" src="/images/icons/brainstormV2/hollow.svg" alt="" @click="enableCanvas('rect')">
                    <img class="mb-2 p-1" src="/images/icons/brainstormV2/solid.svg" alt="" @click="enableCanvas('rect', {fill: skcColor})">
                  </div>
                </div>
              </div>
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-1 relative">
                <div class="realtive">
                  <img src="/images/icons/brainstormV2/image.svg" alt="" @click="clickUploadImage">
                  <input id="img-dot" type="file" @input="uploadImage($event)" class="absolute top-0 bottom-0 invisible" />
                </div>
                <img src="/images/icons/brainstormV2/comment_box.svg" alt="" @click="enableCanvas('textbox')">
                <div class="realtive">
                  <img src="/images/icons/brainstormV2/color.svg" alt="" @click="clickColorPicker" class="">
                  <input id="color-dot" type="color" v-model="skcColor" @input="colorPicker()" class="absolute top-0 bottom-0 invisible" />
                </div>
              </div>
              <div class="flex tool-group ml-5 relative text-secondary-two fw-600 fs-12 items-center">
                <span class="mr-3">Opacity</span>
                <div class="track-box relative">
                  <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full" v-model="skcOpacity" min="0" max="1" step=".1" type="range"/>
                </div>
                <span class="cursor-pointer ml-3" @click="closeSketchCanvas">Close</span>
              </div>
            </div>
          </div>
        </div>
        <div ref="autoScreenshot" class="pan-area">
          <panZoom class="overflow-hidden bs-content-div relative" ref="panZoom" id="pan-zoom-runner"
                   :class="{'bs-content-full-div': navigationClicked === 2}"
                   :options="{transformOrigin: null, minZoom: 0.2, maxZoom: 8, initialX: initialX, initialY: initialY, initialZoom: initialZoom}"
                   @zoom="closeMenuHandler($event)" @panstart="closeMenuHandler($event)">
            <div class="relative" id="container" v-if="activeBrainstormData && activeBrainstormData.scenario_id" ref="sketchScreenshot" :class="{'invisible' : sketch}">
              <div class="flex" v-if="viewIndex === 0">
                <div v-for="(inLoop, loopIndex) in getNumberModules"
                     :key="loopIndex"
                     class="pr-4"
                     :class="{'pl-4': loopIndex > 0, 'border-secondary-two': loopIndex !== 0 && getSectionModules(loopIndex + 1).length > 0 && !isolate}">
                  <div class="flex flex-col pb-8 i-border-b-1" v-for="(module, mIndex) in getSectionModules(loopIndex)"
                       :key="mIndex"
                       :class="{'pt-8': mIndex > 0, 'border-secondary-two': mIndex !== getSectionModules(loopIndex).length - 1 && !isolate}">
                    <component :is="scenarioCanvasType[viewIndex]"
                               :module="module"
                               :appearance="appearance"
                               :indexTitle="indexTitle"
                               :flippedArray="flippedArray"
                               :isolatedModules="isolatedModules"
                               :isolatedModuleId="isolatedModule"
                               :contextModule="contextModule"
                               @newRootChild="newRootChild"
                               @newSubChild="newSubChild"
                               @menu="menuHandler"
                               @toggleFlippedArray="toggleFlippedArray"
                               @changedDataFromCollaboration="changedDataFromCollaboration">
                    </component>
                  </div>
                </div>
              </div>
              <div v-else class="flex-col">
                <div class="flex flex-col i-border-b-1 pb-8"
                     v-for="(inLoop, loopIndex) in getNumberModules"
                     :key="loopIndex"
                     :class="{'pt-8': inLoop > 1 && !isolate, 'border-secondary-two': !isolate}">
                  <div class="flex">
                    <div v-for="(module, mIndex) in getSectionModules(loopIndex)" :key="mIndex" class="i-border-l-1" :class="{'pl-4': mIndex > 0 && !isolate, 'border-secondary-two': mIndex !== 0 && !isolate}">
                      <component :is="scenarioCanvasType[viewIndex]"
                                 :module="module"
                                 :appearance="appearance"
                                 :indexTitle="indexTitle"
                                 :isolatedModules="isolatedModules"
                                 :isolatedModuleId="isolatedModule"
                                 :contextModule="contextModule"
                                 @newRootChild="newRootChild"
                                 @newSubChild="newSubChild"
                                 @menu="menuHandler"
                                 @changedDataFromCollaboration="changedDataFromCollaboration">
                      </component>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="relative" id="container" v-else>
              <component :is="canvasType[viewIndex]"
                         :appearance="appearance"
                         :indexTitle="indexTitle"
                         :flippedArray="flippedArray"
                         :isolatedModules="isolatedModules"
                         :contextModule="contextModule"
                         @newRootChild="newRootChild"
                         @newSubChild="newSubChild"
                         @menu="menuHandler"
                         @toggleFlippedArray="toggleFlippedArray"
                         @changedDataFromCollaboration="changedDataFromCollaboration">
              </component>
              <!-- <sketch-canvas
                  :canvasWidth="1460"
                  :canvasHeight="790"
                  :backgroundColor="'#EDEDED'"
                  :zindex="100"
                  ref="sketch"
                  editorId="sketch"
                  v-show="sketch"
                  class="absolute"
                  style="top: 0; left: 0"
                  :style="{'opacity' : skcOpacity}"
              /> -->
            </div>
            <sketch-canvas
                  :canvasWidth="skcWidth"
                  :canvasHeight="skcHeight"
                  :backgroundColor="getBackgroundColor"
                  :opacity="parseFloat(skcOpacity)"
                  :backgroundImage="sketchScreen"
                  :zindex="40"
                  ref="sketch"
                  editorId="sketch"
                  class="absolute"
                  v-show="sketch"
                  style="top: 0; left: 0;"
              />
          </panZoom>
        </div>
        <context-menu :viewIndex="viewIndex" :item="contextmenuItem" :id="'context-menu-'+activeBrainstormData.module_id" v-show="contextmenu"
                      @appearance="applyAppearnce" @indexTitle="applyIndexTitle"  @background="applyBackground" @description="applyDescription" @isolate="applyIsolation"/>

        <div v-for="(inBrainstorm, bPIndex) in inBrainstormWorking" :key="bPIndex"
             v-if="!concept && !inBrainstorm.in_concept && !inBrainstorm.hide"
             class="absolute"
             :style="{'left': inBrainstorm.x + 'px', 'top': inBrainstorm.y + 'px'}">
          <div class="circe-of-user text-primary-one bg-primary-three py-2 px-2 flex items-center relative shadow-one">
            <img :src="inBrainstorm.avatar" alt="image" class="circle-user" />
            <h4 class="ml-2 fw-600">{{ inBrainstorm.name }}</h4>
            <div class="absolute triangle-div">
              <i class="fa fa-location-arrow rotate-90 fa-lg" aria-hidden="true" :style="{'color': usersBg[bPIndex]}"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="concept && !intel" class="i-right-panel full-height" :class="{'bld-full-w-no-left': navigationClicked === 2}">
      <div class="ws-heading-box flex flex-col relative" v-if="navigationClicked === 1">
        <workspace-menu />
      </div>
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo"
           v-if="navigationClicked === 2">
      <div class="pl-10 i-pr-65 pt-5" :class="{'brainstorm-full-mt': navigationClicked === 2}">
        <div class="flex justify-between items-center">
          <img src="/images/icons/build/navigation.svg" alt="icon" class="cursor-pointer opacity-50 i-icon mr-5 hover:bg-primary-three icon-30 mb-1"
               v-if="navigationClicked === 2" title="Navigation" @click="clickNavigation(1)">
          <div class="absolute tool-pos">
            <div class="tools-panel h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center mb-8">
              <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box" type="text" placeholder="Concept title"
                     v-model="conceptData.title" @change="updateConceptTitle()" :disabled="!conceptData.id" />
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5">
                <img src="/images/icons/brainstormV2/single.svg" alt="new" class="cursor-default pointer-events-none opacity-25">
                <img src="/images/icons/brainstormV2/br_view_details.svg" alt="" class="cursor-pointer br-view-details opacity-75"
                     :class="{'opacity-100 selected-shadow': viewDetails}"
                     @click="viewDetails = !viewDetails" />
                <img src="/images/icons/brainstormV2/link_02.svg" alt="" class="cursor-default pointer-events-none opacity-25">
              </div>
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-5">
                <div class="flex">
                  <div class="flex tool-group i-border-r-1 concept-tool-level-box l1c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[0].visibility}"
                       @click="filterLevel[0].visibility = !filterLevel[0].visibility" />
                  <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l2c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[1].visibility}"
                       @click="filterLevel[1].visibility = !filterLevel[1].visibility"/>
                  <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l3c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[2].visibility}"
                       @click="filterLevel[2].visibility = !filterLevel[2].visibility" />
                  <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l4c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[3].visibility}"
                       @click="filterLevel[3].visibility = !filterLevel[3].visibility"/>
                </div>
              </div>
              <div class="flex tool-group ml-5">
                <div class="relative" v-click-outside="hidePicker">
                  <img src="/images/icons/brainstormV2/color_picker.svg" alt="picker" class="cursor-pointer" @click="showPicker = !showPicker" />
                  <div class="absolute color-picker-div bg-primary-three shadow-one" v-if="showPicker">
                    <div class="flex flex-col items-center justify-center mt-3">
                      <color-picker
                          v-model="getQuadrantsColors[0]"
                          :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,0)" />
                      <color-picker
                          v-model="getQuadrantsColors[1]"
                          :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,1)" />
                      <color-picker
                          v-model="getQuadrantsColors[2]"
                          :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,2)" />
                      <color-picker
                          v-model="getQuadrantsColors[3]"
                          :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,3)" />
                    </div>
                  </div>
                </div>
                <img class="bs-tool-mr-0 cursor-default pointer-events-none opacity-25" src="/images/icons/brainstormV2/chain.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <layout :navigationClicked="navigationClicked"
                  :filterLevel="filterLevel"
                  :isolatedModules="isolatedModules"
                  :quadrantColors="getQuadrantsColors"
                  :pickerUpdated="pickerUpdated"
                  :canvasData="getCanvasData(1)"
                  :canvasSerial="1"
                  :viewDetails="viewDetails"
                  @toggleIsolation="toggleIsolation" />
          <layout v-if="canvasStyle === 2"
                  class="i-ml-28"
                  :navigationClicked="navigationClicked"
                  :filterLevel="filterLevel"
                  :isolatedModules="canvasTwoIsolation"
                  :quadrantColors="getQuadrantsColors"
                  :pickerUpdated="pickerUpdated"
                  :canvasData="getCanvasData(2)"
                  :canvasSerial="2"
                  :viewDetails="viewDetails"
                  @toggleIsolation="toggleIsolation" />
        </div>
      </div>
    </div>

    <div v-else class="i-right-panel full-height" :class="{'bld-full-w-no-left': navigationClicked === 2}">
      <div class="ws-heading-box flex flex-col relative" v-if="navigationClicked === 1">
        <workspace-menu />
      </div>
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo"
           v-if="navigationClicked === 2">
      <div class="pl-10 i-pr-65 pt-5" :class="{'brainstorm-full-mt': navigationClicked === 2}">
        <div class="flex justify-between items-center">
          <img src="/images/icons/build/navigation.svg" alt="icon" class="cursor-pointer opacity-50 i-icon mr-5 hover:bg-primary-three icon-30 mb-1"
               v-if="navigationClicked === 2" title="Navigation" @click="clickNavigation(1)">
          <div class="absolute tool-pos">
            <div class="tools-panel h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center mb-8">
              <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 intel-title-box" type="text" placeholder="Diagram Name"
                     v-model="intelItem.title" @change="updateIntelTitle()" :disabled="!intelItem.id" />
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5">
                <img src="/images/icons/brainstormV2/brainstorm_pencil.svg" alt="picker" class="cursor-pointer opacity-75" />
                <img src="/images/icons/brainstormV2/sketch.svg" alt="" class="cursor-pointer opacity-75"
                     :class="{'opacity-100 shadow-two': viewDetails}" />

                <div class="relative" v-click-outside="hideLinePicker">
                  <img src="/images/icons/brainstormV2/intel_feature_2.svg" alt="picker" class="cursor-pointer opacity-75" @click="showLinePicker = !showLinePicker" />
                  <div class="absolute color-picker-div bg-primary-three shadow-one" v-if="showLinePicker">
                    <div class="flex flex-col items-center justify-center mt-3">
                      <color-picker
                          v-model="intelLineColor"
                          :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setIntelLineColor($event)" />
                    </div>
                  </div>
                </div>

                <img src="/images/icons/brainstormV2/intel_definition.svg" alt="" class="cursor-default pointer-events-none opacity-25">
              </div>
              <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-5">
                <div class="flex">
                  <div class="flex tool-group i-border-r-1 concept-tool-level-box l1c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[0].visibility}"
                       @click="filterLevel[0].visibility = !filterLevel[0].visibility" />
                  <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l2c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[1].visibility}"
                       @click="filterLevel[1].visibility = !filterLevel[1].visibility"/>
                  <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l3c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[2].visibility}"
                       @click="filterLevel[2].visibility = !filterLevel[2].visibility" />
                  <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l4c cursor-pointer i-border-1 border-transparent"
                       :class="{'opacity-50': !filterLevel[3].visibility}"
                       @click="filterLevel[3].visibility = !filterLevel[3].visibility"/>
                </div>
              </div>
              <div class="flex tool-group ml-5">
                <div class="relative" v-click-outside="hidePicker">
                  <img src="/images/icons/brainstormV2/color_picker.svg" alt="picker" class="cursor-pointer" @click="showPicker = !showPicker" />
                  <div class="absolute color-picker-div bg-primary-three shadow-one" v-if="showPicker">
                    <div class="flex flex-col items-center justify-center mt-3">
                      <color-picker
                          v-model="intelChartColor"
                          :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setIntelChartColor($event)" />
                    </div>
                  </div>
                </div>
                <img class="bs-tool-mr-0 cursor-default pointer-events-none opacity-25" src="/images/icons/brainstormV2/chain.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between">
          <intel-layout ref="layout_1"
                        :intelModule="getIntelModule"
                        :parameters="parameters"
                        :serial="1"
                        :intelChartColor="intelChartColor"
                        :intelLineColor="intelLineColor" />
          <intel-layout v-if="intelActiveStyle === 2"
                        ref="layout_2"
                        class="ml-5"
                        :intelModule="getIntelCanvasTwoModule"
                        :parameters="getIntelCanvasTwoParameters"
                        :serial="2"
                        :intelChartColor="intelChartColor"
                        :intelLineColor="intelLineColor" />
        </div>
      </div>
    </div>
    <share-modal :dModal="shareModal" :rootItem="{}" @toggleModalMode="toggleShareModalMode" />
  </div>
</template>

<script>
import LeftPart from "../../../components/brainstormv2/Show/LeftPart";
import HrCanvas from "../../../components/brainstormv2/canvas/HrCanvas";
import SketchCanvas from "../../../components/brainstormv2/canvas/SketchCanvas";
import VrCanvas from "../../../components/brainstormv2/canvas/VrCanvas";
import ScenarioHrCanvas from "../../../components/brainstormv2/canvas/ScenarioHrCanvas";
import ScenarioVrCanvas from "../../../components/brainstormv2/canvas/ScenarioVrCanvas";
import ContextMenu from '../../../components/brainstormv2/canvas/CntxtMenu';
import {mapGetters} from 'vuex';
import Layout from "../../../components/brainstormv2/concept/Layout";
import concept from "../../../store/modules/concept";
import ShareModal from "../../../components/brainstormv2/Show/ShareModal";
import $ from "jquery";
import IntelLayout from "../../../components/brainstormv2/intel/IntelLayout";

export default {
  name: "Show",
  sockets: {
    connect: function () {
      // console.log('socket connected')
    },
    customEmit: function (data) {
      // console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  components: {IntelLayout, Layout, LeftPart, ContextMenu, SketchCanvas, ShareModal},
  created() {
    document.title = "Brainstorm";
    this.zoomReset();
    this.checkCtrl();
    this.$Progress.start();
    this.$store.dispatch("workspace/projectSelection", this.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    if (Object.entries(this.activeBrainstormData).length === 0) {
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id})
          .then(response => {
            if (response) {
              let data = response.data.data
              if (data.module_id || data.modules) {
                let module_id = data.module_id ? data.module_id : data.modules[0].id
                this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", module_id)
                .then(
                  response => {
                    if (response) {
                      this.$Progress.finish();
                    }
                  }
                )
              }
            }
          });
    }
    else {
      if (this.activeBrainstormData.module_id || this.activeBrainstormData.modules) {
        let module_id = this.activeBrainstormData.module_id ? this.activeBrainstormData.module_id : this.activeBrainstormData.modules[0].id
        this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", module_id)
        .then(
          response => {
            if (response) {
              this.$Progress.finish();
            }
          }
        )
      }

    }
  },
  data() {
    return {
      showPicker: false,
      inBrainstormWorking: [],
      pickerUpdated: false,
      usersBg: ['#5B17EA', '#EF5600', '#1C1C1D', '#00A7FE', '#1BAD9C', '#B858BA', '#BCC292', '#0D9D61', '#AD902B', '#BE4949'],
      flippedArray: [],
      viewIndex: 0,
      canvasType: [HrCanvas, VrCanvas],
      scenarioCanvasType: [ScenarioHrCanvas, ScenarioVrCanvas],
      initialX: 40,
      initialY: 160,
      initialYF: 160,
      initialZoom: 1,
      navigationClicked: 1,
      contextmenu: false,
      indexTitle: true,
      description: false,
      contextmenuItem: null,
      appearance: 0,
      isolate: false,
      isolatedItemId: null,
      brainstormThumbnail: '',
      sketchScreen: '',
      concept: false,
      filterLevel: [{ visibility: true }, { visibility: true }, { visibility: true }, { visibility: true }],
      sketch: false,
      sketchMenu: false,
      isolatedIds: [],
      contextModule: null,
      skcOpacity: .75,
      skcColor: "#1C1C1D",
      skcTool: null,
      strokeList: false,
      rectList: false,
      skcCanvas: {
          canvasWidth: 1565,
          canvasHeight: 1080,
          canvasWidthFull: 1920,
          canvasHeightFull: 1080,
          mode: null,
          options: {
              freeDrawing: {},
              text: {},
              rect: { fill: 'transparent', borderRadius: 2 },
              line: {},
              arrow: {},
              comment: {},
          },
      },
      skcWidth: 1565,
      skcHeight: 1080,
      intel: false,
      intelIsolation: [null, null],
      strkList: [
        '../../../assets/images/icons/brainstormV2/pen.svg',
        '../../../assets/images/icons/brainstormV2/pen.svg',
        '../../../assets/images/icons/brainstormV2/pen.svg',
        '../../../assets/images/icons/brainstormV2/pen.svg',
      ],
      shareModal: false,
      viewDetails: false,
      intelChartBg: null,
      intelLineBg: null,
      showLinePicker: false,
      isolatedModules: [],
      isolatedModule: null
    }
  },
  watch: {
    skcOpacity: function (val) {
      if (val) {
        this.renderCanvas()
      }
    },
    getQuadrantsColors: function(val) {
      this.pickerUpdated = true;
    },
    navigationClicked: function(val) {
      this.zoomReset()
    },
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      conceptData: "concept/concept",
      onlineUsers: "socket/inBrainstormPage",
      canvasPropValue: "concept/canvasPropValue",
      canvasFirstModule: "concept/canvasFirstModule",
      canvasSecondModule: "concept/canvasSecondModule",
      intelItem: "intel/intelItem",
      intelModule: "intel/intelModule",
      parameters: "intel/parameters",
      canvasTwo: "intel/canvasTwo",
      canvasTwoIsolation: "concept/canvasTwoIsolation",
    }),
    canvasStyle() {
      return this.conceptData.style;
    },
    getModules() {
      if(this.activeBrainstormData) {
        return this.activeBrainstormData.modules;
      }
      return [];
    },
    getNumberModules() {
      if(this.isolatedModule) {
        return 1;
      } else {
        return this.getModules.length / 3;
      }
    },
    getCanvasType() {
      if(this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
        return this.scenarioCanvasType;
      }
      return this.canvasType;
    },
    getBackgroundColor() {
      let opacity = this.skcOpacity
      return 'rgba(237, 237, 237,'+ opacity +')'
    },
    moduleSettings() {
      if(this.activeBrainstormData.module) {
        let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
        if(settings) {
          return settings.collections;
        }
        return [];
      }
      return [];
    },
    getQuadrantsColors() {
      if(this.conceptData && this.conceptData.colors) {
        return this.conceptData.colors;
      }
      return ['', '', '', '',];
    },
    intelActiveStyle() {
      return this.intelItem && this.intelItem.style ? this.intelItem.style : 0;
    },
    getIntelCanvasTwoModule() {
      if(this.canvasTwo && this.canvasTwo.module && this.canvasTwo.module.id) {
        return this.canvasTwo.module;
      }
      return null;
    },
    getIntelCanvasTwoParameters() {
      if(this.canvasTwo && this.canvasTwo.parameters && this.canvasTwo.parameters.length > 0) {
        return this.canvasTwo.parameters;
      }
      return [];
    },
    getIntelModule() {
      return this.intelModule;
      // if(this.activeBrainstormData.scenario_id) {
      //   return this.intelModule;
      // } else {
      //   return this.getModuleForIntel();
      // }
    },
    intelChartColor: {
      get: function() {
        if(this.intelChartBg) {
          return this.intelChartBg;
        } else {
          if(this.intelItem && this.intelItem.colors && this.intelItem.colors.length > 1 && this.intelItem.colors[1].length > 0) {
            return this.intelItem.colors[1][0];
          }
          return "#F9F9F9";
        }
      },
      set: function(newValue) {
        this.intelChartBg = newValue;
      }
    },
    intelLineColor: {
      get: function() {
        if(this.intelLineBg) {
          return this.intelLineBg;
        } else {
          if(this.intelItem && this.intelItem.colors && this.intelItem.colors.length > 1) {
            return this.intelItem.colors[0];
          }
          return "#E2E2E2";
        }
      },
      set: function(newValue) {
        this.intelLineBg = newValue;
      }
    }
  },
  methods: {
    getModuleForIntel() {
      let module = this.activeBrainstormData.module;
      module.collections = this.activeBrainstormData.collections;
      return module;
    },
    getCanvasData(serial) {
      if(serial === 1) {
        if(this.canvasPropValue.canvas_one_h && this.canvasPropValue.canvas_one_v && this.canvasFirstModule) {
          return this.canvasFirstModule;
        }
      } else {
        if(this.canvasPropValue.canvas_two_h && this.canvasPropValue.canvas_two_v && this.canvasSecondModule) {
          return this.canvasSecondModule;
        }
      }
      return null;
    },
    hidePicker() {
      this.showPicker = false;
    },
    hideLinePicker() {
      this.showLinePicker = false;
    },
    colorChange() {
      this.hidePicker();
    },
    setQuadrantColors(e, index) {
      this.pickerUpdated = true;
      if(this.conceptData) {
        let colorsArray = this.getQuadrantsColors;
        colorsArray[index] = e;
        let data = {
          id: this.conceptData.id,
          title: this.conceptData.title,
          colors: colorsArray,
          project_id: this.$route.params.id,
          brainstorm_id: this.$route.params.brainstorm_id,
          stop: true
        };
        this.$store.dispatch("concept/updateConcept", data);
      }
    },
    loadPageData() {
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id});
    },
    async autoScreenshot() {
      const el = this.$refs.autoScreenshot;
      const options = {
        type: 'dataURL'
      }
      this.brainstormThumbnail = await this.$html2canvas(el, options);
    },
    async sketchScreenshot() {
      // const el = this.$refs.sketchScreenshot
      const el = document.getElementById('container')
      const options = {
        type: 'dataURL',
        backgroundColor:null,
        height: this.skcHeight,
        width: this.skcWidth
      }
      this.sketchScreen = await this.$html2canvas(el, options);

      let data = {}
      data.title = 'New Brainstorm Sketch'
      data.brainstorm_id = this.activeBrainstormData.id
      this.$store.dispatch('brainstormv1/storeSketchItem', data)
    },
    toggleConcept() {
      this.concept = !this.concept;
      if(this.concept) {
        this.notifyOnCursorMoving({ x: 0, y: 0, in_concept: true, hide: true });
      } else {
        this.notifyOnCursorMoving({ x: 0, y: 0, in_concept: false, hide: true });
      }
    },
    closeConcept(event) {
      this.concept = false;
      this.notifyOnCursorMoving({ x: event.pageX, y: event.pageY, in_concept: false, hide: true });
    },
    gotoConcept(event) {
      this.concept = true;
      this.notifyOnCursorMoving({ x: event.pageX, y: event.pageY, in_concept: true });
    },
    toggleToIntel() {
      this.concept = false;
      this.sketch = false;
      this.intel = true;

      const self = this;
      setTimeout(() => {
        if(self.intelModule && self.intelModule.id) {
          self.$refs.layout_1.setRootCollections();
          self.$refs.layout_1.setChartData();
        }
        if(self.intelActiveStyle === 2 && self.canvasTwo && self.canvasTwo.module && self.canvasTwo.parameters && self.canvasTwo.parameters.length > 0) {
          self.$refs.layout_2.setRootCollections();
          self.$refs.layout_2.setChartData(true);
        }
      }, 500);

      // if(this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
      //   console.log(this.activeBrainstormData);
      //   this.$store.dispatch('intel/getAssessments', {scenario_id: this.activeBrainstormData.scenario_id})
      // }
    },
    closeIntel() {
      this.intel = false;
    },
    intelChartIsolation(data, itemId, serial) {
      if(this.intelIsolation[serial - 1] === itemId) {
        this.intelIsolation[serial - 1] = null;
        data = [];
      } else {
        this.intelIsolation[serial - 1] = itemId;
      }

      if(serial === 1) {
        this.$refs.layout_1.setIsolatedData(data);
      } else {
        this.$refs.layout_2.setIsolatedData(data);
      }
    },
    removeIntelIsolation(data, serial) {
      this.intelIsolation[serial - 1] = data;
      if(serial === 1) {
        this.$refs.layout_1.setIsolatedData(data);
      } else {
        this.$refs.layout_2.setIsolatedData(data);
      }
    },
    setIntelGroup(data, serial) {
      if(serial === 1) {
        this.$refs.layout_1.setIntelGroupData(data);
      } else {
        this.$refs.layout_2.setIntelGroupData(data);
      }
    },
    setIntelChartColor(e) {
      this.intelChartBg = e;
      // this.showPicker = false;
      let data = {
        id: this.intelItem.id,
        title: this.intelItem.title,
        colors: [this.intelLineColor, [this.intelChartBg]]
      };
      this.$store.dispatch("intel/updateIntel", data);
    },
    setIntelLineColor(e) {
      this.intelLineBg = e;
      // this.showLinePicker = false;
      let data = {
        id: this.intelItem.id,
        title: this.intelItem.title,
        colors: [this.intelLineBg, [this.intelChartColor]]
      };
      this.$store.dispatch("intel/updateIntel", data);
    },
    clickNavigation(val) {
      if(val) {
        this.navigationClicked = val;
      } else {
        if(this.navigationClicked === 2) {
          this.navigationClicked = 1;
        } else {
          this.navigationClicked = 2;
        }
      }

      if (this.navigationClicked == 1) {
        this.skcWidth = this.skcCanvas.canvasWidth
        this.skcHeight = this.skcCanvas.canvasHeight
        this.initialY = 160
      }
      else if (this.navigationClicked == 2) {
        this.skcWidth = this.skcCanvas.canvasWidthFull
        this.initialY = this.initialYF
        this.skcHeight = this.skcCanvas.canvasHeightFull
      }
    },
    toggleView() {
      if (this.viewIndex == 0) {
        this.viewIndex = 1
      } else if (this.viewIndex == 1) {
        this.viewIndex = 0
      }
    },
    updateTitle() {
      let data = {
        id: this.activeBrainstormData.id,
        title: this.activeBrainstormData.title
      };
      this.$store.dispatch("brainstormv1/updateBrainstorm", data);
    },
    newRootChild(moduleId = null, order = null) {
      let data = {
        parent_id: null,
        brainstorm_id: this.$route.params.brainstorm_id,
        level: 1,
        title: this.moduleSettings.length > 0 ? this.moduleSettings[1].title : 'Identifier',
      }

      this.$Progress.start();
      if(this.activeBrainstormData.scenario_id) {
        data.scenario_id = this.activeBrainstormData.scenario_id;
        data.item_order = data.order;
        data.from_right_side = false;
        data.module_id = moduleId;
        data.order = order;
        this.toScenarioCreate(data);
      } else {
        data.order = this.activeBrainstormData.collections.length > 0 ? this.activeBrainstormData.collections[this.activeBrainstormData.collections.length - 1].order + 1 : 1
        if(this.activeBrainstormData.module_id) {
          data.item_order = data.order;
          data.from_right_side = false;
          data.module_id = this.activeBrainstormData.module_id;
          this.toBuildCreate(data);
        } else {
          this.toBrainstormCreate(data);
        }
      }
    },
    newSubChild(data) {
      this.$Progress.start();
      if(this.activeBrainstormData.scenario_id) {
        data.scenario_id = this.activeBrainstormData.scenario_id;
        data.item_order = data.order;
        data.from_right_side = false;
        this.toScenarioCreate(data);
      } else {
        if(this.activeBrainstormData.module_id) {
          data.module_id = this.activeBrainstormData.module_id;
          data.item_order = data.order;
          data.from_right_side = false;
          this.toBuildCreate(data);
        } else {
          this.toBrainstormCreate(data);
        }
      }
    },
    toBuildCreate(data) {
      this.$store.dispatch("build/storeData", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
          this.$Progress.finish();
        });
      });
      this.changedDataFromCollaboration({ load: true });
    },
    toBrainstormCreate(data) {
      this.$store.dispatch("brainstormv1/storeItem", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$Progress.finish();
      });

      this.changedDataFromCollaboration({ load: true });
    },
    toScenarioCreate(data) {
      this.$store.dispatch("programmatic/storeScenarioCollectionItem", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
          this.$Progress.finish();
        });
      });

      this.changedDataFromCollaboration({ load: true });
    },
    notifyUpdate(data) {
      this.changedDataFromCollaboration(data);
    },
    zoomReset() {
      let self = this;
      setTimeout(() => {
        let inst = self.$refs.panZoom;
        if (inst && inst.$panZoomInstance) {
          inst.$panZoomInstance.moveTo(self.initialX, self.initialY)
          inst.$panZoomInstance.zoomAbs(self.initialX, self.initialY, self.initialZoom);
        }
      }, 50);
    },
    // controlled scroll
    menuHandler(obj) {
      this.contextmenu = true
      this.contextmenuItem = obj.item
      this.contextModule = this.contextmenuItem.module_id;
      let top = obj.top
      let left = obj.left
      let context = document.getElementById('context-menu-' + this.activeBrainstormData.module_id)
      context.style.left = left+'px'
      context.style.top = top+'px'
    },
    closeMenuHandler(e) {
        var context = document.getElementById('context-menu-' + this.activeBrainstormData.module_id);
        if (context && !context.contains(e.target)) {
          this.contextmenu = false
        }
    },
    applyAppearnce(value) {
      this.appearance = value
      this.contextmenu = false
    },
    applyIndexTitle(value) {
      this.indexTitle = value
      this.contextmenu = false
    },
    applyDescription(value) {
      this.description = value
      this.contextmenu = false
    },
    applyIsolation(value) {
      // this.isolate = value;
      // this.contextmenu = false;
      // this.contextModule = this.contextmenuItem.module_id;
      // if(this.isolate) {
      //   this.getIds(this.contextmenuItem);
      // } else {
      //   this.isolatedIds = [];
      // }
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      let obj = {... this.contextmenuItem}
      obj.created_by = this.contextmenuItem.created_by.id;
      if (this.activeBrainstormData.module_id) {
        this.$store.dispatch("build/regularUpdate", obj)
      }
      if (this.activeBrainstormData.scenario_id) {
        this.$store.dispatch("programmatic/updateCollection", obj);
      }

      this.contextmenu = false
    },
    updateConceptTitle() {
      let data = {
        id: this.conceptData.id,
        title: this.conceptData.title
      };
      this.$store.dispatch("concept/updateConcept", data);
    },
    updateIntelTitle() {
      let data = {
        id: this.intelItem.id,
        title: this.intelItem.title
      };
      this.$store.dispatch("intel/updateIntel", data);
    },
    getSectionModules(sectionIndex) {
      if(this.isolatedModule) {
        return this.activeBrainstormData.modules.filter(module => module.id === this.isolatedModule);
      } else {
        let initial = sectionIndex * 3;
        let array = [];
        array.push(this.activeBrainstormData.modules[initial]);
        array.push(this.activeBrainstormData.modules[initial + 1]);
        array.push(this.activeBrainstormData.modules[initial + 2]);
        return array;
      }
    },
    toggleSketchMenu() {
      this.sketchMenu = !this.sketchMenu;
    },
    generateSketchCanvas() {
      this.$refs.panZoom.pause()
      this.sketchMenu = false
      this.sketchScreenshot()
      setTimeout(() => {
        this.sketch = true
      }, 200);
      this.enableCanvas('selectMode')
    },
    closeSketchCanvas() {
      this.clearSketch()
      this.$refs.panZoom.resume()
    },
    toggleFlippedArray(data) {
      let index = this.flippedArray.findIndex(item => parseInt(item.id) === parseInt(data.id));
      if(index >= 0) {
        let item = this.flippedArray.find(item => parseInt(item.id) === parseInt(data.id));
        if(item.flip_count === 1) {
          item.flip_count = 2;
        } else {
          this.flippedArray.splice(index, 1);
        }
      } else {
        data.flip_count = 1;
        this.flippedArray.push(data);
      }
    },
    getIds(item) {
      this.isolatedIds.push(item.id);
      item.child.forEach(child => {
        this.getIds(child);
      });
    },
    toggleIsolation(item) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === item.module_id);
      if(moduleIndex >= 0) {
        let moduleIsolation = this.isolatedModules[moduleIndex];
        if(moduleIsolation.isolatedItemId !== item.id) {
          this.prepareIsolationData(item);
        }
        this.isolatedModules.splice(moduleIndex, 1);
      } else {
        this.prepareIsolationData(item);
      }
      this.zoomReset();
    },
    prepareIsolationData(item) {
      let isolateData = item.parents;
      let data = {
        module_id: item.module_id,
        isolate: true,
        isolatedIds: this.getChildIdForIsolation(item, isolateData),
        isolatedItemId: item.id
      }
      this.isolatedModules.push(data);
    },
    getChildIdForIsolation(item, isolateData) {
      isolateData.push(item.id);
      item.child.forEach(child => {
        isolateData = this.getChildIdForIsolation(child, isolateData);
      });
      return isolateData;
    },
    removeIsolation(moduleId) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === moduleId);
      if(moduleIndex >= 0) {
        this.isolatedModules.splice(moduleIndex, 1);
      }
    },
    moduleIsolation(moduleId) {
      if (moduleId === this.isolatedModule) {
        this.isolatedModule = null;
      } else {
        this.isolatedModule = moduleId;
      }
    },
    isInIsolate(item) {
      let isIndexed = this.isolatedIds.findIndex(id => parseInt(item.id) === parseInt(id));
      return isIndexed >= 0;
    },
    notifyOnCursorMoving(coordination) {
      let loggedUser = JSON.parse(localStorage.getItem('_user'));
      let receivers = this.onlineUsers.filter(item => parseInt(item) !== parseInt(loggedUser.id)).map(user => user);
        let x = [1, 2, 3,];
      if(receivers.length > 0) {
        let data = {
          x: coordination.x,
          y: coordination.y,
          user_id: parseInt(loggedUser.id),
          name: loggedUser.first_name,
          avatar: 'https://picsum.photos/200',
          in_concept: coordination.in_concept ? coordination.in_concept : false,
          working_in_device_width: (window.innerWidth > 0) ? window.innerWidth : screen.width,
          hide: coordination.hide
        };

        this.$socket.emit('brainstorm_cursor_moved', {
          receivers: receivers,
          data: data
        });
      }
    },
    movedCursorPosition(event, out = false) {
      let coordination = {
        x: event.pageX,
        y: event.pageY,
        hide: out
      };
      this.notifyOnCursorMoving(coordination);

      // clientX/Y gives the coordinates relative to the viewport in CSS pixels.
      // console.log(event.clientX);
      // console.log(event.clientY);

      // pageX/Y gives the coordinates relative to the <html> element in CSS pixels.
      // console.log(event.pageX);
      // console.log(event.pageY);

      // screenX/Y gives the coordinates relative to the screen in device pixels.
      // console.log(event.screenX);
      // console.log(event.screenY);
    },
    clickColorPicker() {
      let picker = document.getElementById('color-dot')
      picker.click()
    },
    colorPicker() {
      this.$refs.sketch.changeColor(this.skcColor);
      // this.skcCanvas.options["rect"].fill = this.skcColor;
      this.skcCanvas.options["arrow"].fill = this.skcColor;

      if (this.skcCanvas.mode != "selectMode" && this.skcCanvas.mode != "textbox") {
          this.enableCanvas(this.skcCanvas.mode);
      }
    },
    enableCanvas(mode) {
        this.$refs.sketch.changeColor(this.skcColor);
        this.enabledTool = mode;
        this.skcCanvas.mode = mode;
        this.$refs.sketch.set(mode, this.skcCanvas.options[mode]);
    },
    disableCanvas() {
        // console.log("here");
        this.skcCanvas.mode = "selectMode";
        this.$refs.sketch.set(this.skcCanvas.mode);
        // this.$refs.editor.getCanvasZoom();
        this.enabledTool = '';
        // $(".draggable").css("z-index", 1);
    },
    // enableCanvas(mode) {
    //     this.enabledTool = mode;
    //     this.canvas.mode = mode;
    //     if (this.sketch) {
    //         this.$refs.sketch.set(mode, this.canvas.options[mode]);
    //         this.$refs.editor.set("selectMode");
    //     } else {
    //         this.$refs.editor.set(mode, this.canvas.options[mode]);
    //         this.$refs.sketch.set("selectMode");
    //     }

    //     // $(".draggable").css("z-index", "auto");
    // },
    // disableCanvas() {
    //     // console.log("here");
    //     this.canvas.mode = "selectMode";
    //     this.$refs.editor.set(this.canvas.mode);
    //     this.$refs.sketch.set(this.canvas.mode);
    //     this.$refs.editor.getCanvasZoom();
    //     this.enabledTool = '';
    //     // $(".draggable").css("z-index", 1);
    // },
    addSketch() {
        this.sketch = true;
        setTimeout(() => {
            this.$refs.sketch.changeColor(this.color);
            this.$refs.sketch.set("selectMode");
        }, 100);

    },
    closeSketch() {
        let skcanvas = this.$refs.sketch
        let objects = skcanvas.canvas.getObjects()
        let data = []
        if (objects.length > 0) {
            objects.forEach(element => {
                let type = element.get('type');
                let obj = {}
                obj.type = type
                obj.width = element.width
                obj.height = element.height
                obj.fill = element.fill
                obj.left = element.left
                obj.top = element.top
                obj.scaleX = element.scaleX
                obj.scaleY = element.scaleY
                obj.zoomX = element.zoomX
                obj.zoomY = element.zoomY
                obj.angle = element.angle ? element.angle : 0
                if (type == 'group') {
                    obj.items = element._objects
                }
                else if (type == 'i-text') {
                    obj.fontFamily = element.fontFamily
                    obj.fontSize = element.fontSize
                    obj.placeholder = element.text
                }
                else if (type == 'lineArrow') {
                    obj.stroke = element.stroke
                    obj.strokeWidth = element.strokeWidth
                    obj.heads = element.heads
                    obj.x1 = element.x1
                    obj.x2 = element.x2
                    obj.y1 = element.y1
                    obj.y2 = element.y2
                }
                else if (type == 'path') {
                    obj.stroke = element.stroke
                    obj.strokeLineCap = element.strokeLineCap
                    obj.strokeLineJoin = element.strokeLineJoin
                    obj.strokeMiterLimit = element.strokeMiterLimit
                    obj.strokeWidth = element.strokeWidth
                    obj.path = element.path
                }
                data.push(obj)

            });

            let str = JSON.stringify(data)

            let bsdata = {}
            bsdata.id = this.activeBrainstorm.id
            bsdata.sketch_data = str

            this.$store.dispatch('build/updateBrainstormItem', bsdata)
            .then(
                this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id),
                this.$store.dispatch('build/getActiveBrainstorm', this.activeBrainstorm.id)
            )
        }
        else {
            //
        }
        this.sketch = false;
    },
    save() {
        let objects = this.$refs.editor.canvas.getObjects()
        let data = []
        if (objects.length > 0) {
            objects.forEach(element => {
                let type = element.get('type');
                let obj = {}
                obj.type = type
                obj.width = element.width
                obj.height = element.height
                obj.fill = element.fill
                obj.left = element.left
                obj.top = element.top
                obj.scaleX = element.scaleX
                obj.scaleY = element.scaleY
                obj.zoomX = element.zoomX
                obj.zoomY = element.zoomY
                obj.angle = element.angle ? element.angle : 0
                if (type == 'group') {
                    obj.items = element._objects
                }
                else if (type == 'i-text') {
                    obj.fontFamily = element.fontFamily
                    obj.fontSize = element.fontSize
                    obj.placeholder = element.text
                }
                else if (type == 'lineArrow') {
                    obj.stroke = element.stroke
                    obj.strokeWidth = element.strokeWidth
                    obj.heads = element.heads
                    obj.x1 = element.x1
                    obj.x2 = element.x2
                    obj.y1 = element.y1
                    obj.y2 = element.y2
                }
                else if (type == 'path') {
                    obj.stroke = element.stroke
                    obj.strokeLineCap = element.strokeLineCap
                    obj.strokeLineJoin = element.strokeLineJoin
                    obj.strokeMiterLimit = element.strokeMiterLimit
                    obj.strokeWidth = element.strokeWidth
                    obj.path = element.path
                }
                data.push(obj)

            });
            // console.log(data);

            let str = JSON.stringify(data)

            let bsdata = {}
            bsdata.module_collection_id = this.activeModuleCollection.id
            bsdata.title = document.getElementById('brtitle').value
            bsdata.data = str

            this.$store.dispatch('build/storeBrainstormData', bsdata)
            .then(
                this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id)
            )
        }
        else {
            alert("Please draw something to save!")
        }
    },
    update() {
        let objects = this.$refs.editor.canvas.getObjects()
        let data = []
        if (objects.length > 0) {
            objects.forEach(element => {
                let type = element.get('type');
                let obj = {}
                obj.type = type
                obj.width = element.width
                obj.height = element.height
                obj.fill = element.fill
                obj.left = element.left
                obj.top = element.top
                obj.scaleX = element.scaleX
                obj.scaleY = element.scaleY
                obj.zoomX = element.zoomX
                obj.zoomY = element.zoomY
                obj.angle = element.angle ? element.angle : 0
                if (type == 'group') {
                    obj.items = element._objects
                }
                else if (type == 'i-text') {
                    obj.fontFamily = element.fontFamily
                    obj.fontSize = element.fontSize
                    obj.placeholder = element.text
                }
                else if (type == 'lineArrow') {
                    obj.stroke = element.stroke
                    obj.strokeWidth = element.strokeWidth
                    obj.heads = element.heads
                    obj.x1 = element.x1
                    obj.x2 = element.x2
                    obj.y1 = element.y1
                    obj.y2 = element.y2
                }
                else if (type == 'path') {
                    obj.stroke = element.stroke
                    obj.strokeLineCap = element.strokeLineCap
                    obj.strokeLineJoin = element.strokeLineJoin
                    obj.strokeMiterLimit = element.strokeMiterLimit
                    obj.strokeWidth = element.strokeWidth
                    obj.path = element.path
                }
                data.push(obj)

            });
            // console.log(data);

            let str = JSON.stringify(data)

            let bsdata = {}
            bsdata.id = this.activeBrainstorm.id
            bsdata.title = document.getElementById('brtitle').value
            bsdata.data = str

            this.$store.dispatch('build/updateBrainstormItem', bsdata)
            .then(
                this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id),
                this.$store.dispatch('build/getActiveBrainstorm', this.activeBrainstorm.id)
            )
        }
        else {
            alert("Please draw something to save!")
        }
    },
    // updateTitle() {
    //     if (this.activeBrainstorm) {
    //         let title = document.getElementById('brtitle').value
    //         if (!title) {
    //             document.getElementById('brtitle').value = this.activeBrainstorm.title
    //             return false
    //         }
    //         let data = {
    //             id: this.activeBrainstorm.id,
    //             title: title
    //         }
    //         this.$store.dispatch('build/updateBrainstormItem', data)
    //         .then(
    //             this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id),
    //             this.$store.dispatch('build/getActiveBrainstorm', this.activeBrainstorm.id)
    //         )
    //     }

    // },
    clickUploadImage() {
      let picker = document.getElementById('img-dot')
      picker.click()
    },
    uploadImage(e) {
      this.$refs.sketch.uploadImage(e);
    },
    clearSketch() {
        this.sketch = false
        this.$refs.sketch.clear();
        this.skcOpacity = .75
        this.$refs.sketch.renderCanvas();
    },
    renderCanvas() {
        this.$refs.sketch.renderCanvas();
    },
    changedDataFromCollaboration(data) {
      let loggedUser = JSON.parse(localStorage.getItem('_user'));
      let receivers = this.onlineUsers.filter(item => parseInt(item) !== parseInt(loggedUser.id)).map(user => user);

      if(receivers.length > 0) {
        this.$socket.emit('brainstorm_data_updated', {
          receivers: receivers,
          data: data
        });
      }
    },
    toggleShareModalMode(value) {
      this.shareModal = value;
    },
    checkCtrl(e) {
      let inst = this.$refs.panZoom
      if (inst && inst.$panZoomInstance) {
        let instance = inst.$panZoomInstance
        if (e.altKey) {
          instance.resume();
        }
        else {
          instance.pause();
        }
      }
    }

  },
  mounted() {
    this.$refs.sketch.getCanvasZoom();
    this.$refs.panZoom.$panZoomInstance.pause();

    //socket code
    // let loggedUser = JSON.parse(localStorage.getItem('_user'));
    // this.$socket.emit('landed_in_brainstorm', {user_id: loggedUser.id});
    //
    // this.sockets.subscribe('landed_in_brainstorm', function (res) {
    //   if(res) {
    //     this.$store.dispatch("socket/landedInBrainstormPage", res.data);
    //   }
    // });
    //
    // this.sockets.subscribe('brainstorm_cursor_moved', function (res) {
    //   if(res) {
    //     let alreadyIn = this.inBrainstormWorking.find(data => parseInt(data.user_id) === parseInt(res.user_id));
    //     if(alreadyIn) {
    //       let myDeviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    //       // alreadyIn.x = (myDeviceWidth * res.x) / res.working_in_device_width;
    //       alreadyIn.x = res.x;
    //       alreadyIn.y = res.y;
    //       alreadyIn.in_concept = res.in_concept;
    //       alreadyIn.hide = res.hide;
    //     } else {
    //       this.inBrainstormWorking.push(res);
    //     }
    //   }
    // });
    //
    // this.sockets.subscribe('leave_from_brainstorm', function (res) {
    //   if(res) {
    //     this.$store.dispatch("socket/leaveFromBrainstormPage", res.user_id);
    //     let userIndex = this.inBrainstormWorking.findIndex(item => parseInt(item.user_id) === parseInt(res.user_id));
    //     if(userIndex >= 0) {
    //       this.inBrainstormWorking.splice(userIndex, 1);
    //     }
    //   }
    // });
    //
    // this.sockets.subscribe('brainstorm_data_updated', function (res) {
    //   if(res) {
    //     if(res.load) {
    //       this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id})
    //     }
    //   }
    // });
  },
  beforeDestroy() {
    let loggedUser = JSON.parse(localStorage.getItem('_user'));
    let receivers = this.onlineUsers.filter(item => parseInt(item) !== parseInt(loggedUser.id)).map(user => user);

    if(receivers.length > 0) {
      this.$socket.emit('leave_from_brainstorm', {
        receivers: receivers,
        user_id: parseInt(loggedUser.id)
      });
    }
  },
}
</script>

<style scoped>
.tools-panel {
  width: 860px;
}

.tool-group img {
  margin-right: 20px;
  cursor: pointer;
  /* opacity: .6; */
}

.tool-group img:hover {
  opacity: 1;
}

.bs-content-div {
  height: 1080px;
}
.bs-content-full-div {
  height: 1080px;
}

.tool-group .bs-tool-mr-0 {
  margin-right: 0;
}
.brainstorm-full-mt {
  margin-top: 54px;
}
.icon-30 {
  width: 30px;
  height: 30px;
}
.tool-level-box {
  width: 10px;
  height: 16px;
  border-radius: 1px;
}
.concept-tool-level-box {
  width: 32px;
  height: 16px;
  border-radius: 2px;
}
.l1c {
  background: #2B80AD;
}
.l2c {
  background: #3D6A83;
}
.l3c {
  background: #6A7881;
}
.l4c {
  background: #818487;
}
.concept-title-box {
  width: 380px;
}
.intel-title-box {
  width: 320px;
}
#sketch-menu {
  top: 33px;
  left: 115px;
  width: 275px;
}
.sketch-item:hover {
  opacity: 1;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #3D3D3D;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
.icon-list {
  border-radius: 0px 0px 2px 2px;
  z-index: 999;
  top: 33px;
  left: -15px;
}
.circe-of-user {
  border-radius: 17px;
  z-index: 9999;
}
.circle-user {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.triangle-div {
  top: -18px;
  left: -16px;
  font-size: 1.4rem;
}

/*arrow*/
.rotate-90 {
  transform: rotate(
      -90deg
  );
}
.vue-pan-zoom-scene {
  height: 790px
}
#container {
  width: fit-content;
  padding-right: 1000px;
  padding-bottom: 1000px;
}
.color-picker-div {
  border-radius: 0 0 2px 2px;
  width: 32px;
  z-index: 9999999
}
.pan-area {
  z-index: 1;
}
.tools-panel-cont {
  left: 40px;
  top: 160px;
}
.tools-panel-cont-full {
  left: 40px;
  top: 100px;
}
.tools-panel-cont-full-width {
  width: 1840px;
}
.tool-pos {
  top: 94px;
}
.tool-cont-pos {
  top: -64px;
}
.tool-cont-pos-full {
  top: -6px;
  left: 70px;
}
.br-view-details {
  border-radius: 2px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.13);
}
.selected-shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
